.confimation-hero-section {
  height: 600px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.main-img-1 {
  background: url("../../../../public/images/Red.png");
}
.main-img-2 {
  background: url("../../../../public/images/Orange.png");
}
.main-img-3 {
  background: url("../../../../public/images/Blue.png");
}
.main-img-4 {
  background: url("../../../../public/images/Purplenew.png");
}
.main-img-5 {
  background: url("../../../../public/images/Green.png");
}
.main-img-6 {
  background: url("../../../../public/images/Yellow.png");
}

.confirmation_banner {
  height: 440px !important;
}
.confirmation-red {
  text-align: center;
}
.confirmation-red h4,
.congratuation-content-bottom h3,
.congratuation-content-bottom p {
  color: #fff;
}

.confirmation-red h2 {
  color: #fff;
  font-size: 70px;
  font-weight: 800;
  padding-bottom: 1.5rem;
}
.congratuation-content-bottom {
  max-width: 600px;
  margin: 0 auto;
}
.confirmation-top{
  margin-top: 100px;
}

@media screen and (max-width:767px){
  .confirmation_banner {
    height: 500px !important;
  }
}
