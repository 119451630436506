.howitworks-container {
  background-color: #f7f0ea;
  width: 100%;
  padding: 140px 0 70px;
}

.howitworks-content h2 {
  font-weight: 400;
  max-width: 620px;
}
.color-bar {
  /* max-width: 860px; */
  padding-top: 30px;
}
.how-it-works-icon.step.font-lg-32 {
  margin-bottom: 0px;
}
.color-bar .how-it-works-icon {
  padding: 150px 0 96px;
}

.multi-color-section {
  background: url("../../../images/multi-color-bar.png");
  height: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  width: 90%;
}

.how-it-works-icon {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.play-icon {
  background: url("../../../images/play-icon.png");
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-size: cover;
}

.how-it-works-icon span {
  font-weight: 800;
}

.dress-steps {
  max-width: 1344px;
}

.dress-steps .dress-steps-item {
  width: 100%;
  min-height: 200px;
  height: 100%;
  padding: 60px 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid #0000;
}
.dress-steps .dress-steps-item img {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  display: block;
}
.step-label p{
  color: #ec7b3d;
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
}
.step-content p {
  color: black;
  font-size: 16px;
  font-weight: 500;
}
/* Media Query */
@media screen and (min-width: 768px) and  (max-width: 991px){
  .howitworks-container {
    padding: 40px 0 0px;
  }
}

@media screen and (max-width: 767px) {
  .streamline-container .row div:first-child .dress-steps-item {
    padding-top: 60px;
  }

  .howitworks-container {
    margin-top: 0;
    padding: 0px 0;
    min-height: auto;
}
  .howitworks-content,
  .color-bar {
    font-size: 19px;
    padding: 1.5rem 0rem;
  }
    .color-bar .how-it-works-icon {
      padding: 70px 0 30px;
    }
    .dress-steps .col-md-4 {
      margin-bottom: 2rem;
    }
    .dress-steps .dress-steps-item {
      width: 94%;
      min-height: 151px;
      height: 100%;
      padding: 30px 15px;
      margin: auto;
    }
    .dress-steps .dress-steps-item img {
      max-width: 300px;
    }
    .navbar-container.nav-white {
      padding-bottom: 15px;
    }
    .how-it-works-icon.font-lg-32 {
      font-size: 22px;
    }
}
