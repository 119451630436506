
.terms_services h1 {
      display: flex;
      justify-content: center;
      margin-bottom: 60px;  
  }
  .TermsAndServices{
      max-width: 1200px !important;
  }
  h4.term_h4 {
    margin-bottom: 22px;
    margin-top: 20px;
}
  