.clothes {
  text-align: center;
  margin: 20px 0px;
  padding: 10px 0px;
  border-top: 1px solid #a8a6a6;
  border-bottom: 1px solid #a8a6a6;
}

.tops {
  font-weight: 500;
}

.container-shapes_list {
  display: flex;
  justify-content: center;
}

ol.list-styling {
  counter-reset: listStyle;
}

.Red ol.list-styling li:before {
  background: #e73545;
}
.Orange ol.list-styling li:before {
    background:  #ef8343;
}
.Green ol.list-styling li:before {
    background: #94c247;
}
.Purple ol.list-styling li:before {
    background: #4b4ca7;
}
.Blue ol.list-styling li:before {
    background: #49cbd5;
}
.Yellow ol.list-styling li:before {
    background: #f5d103;
}

.how-it-works-icon.font-lg-32.sheet {
  margin-bottom: 50px;
}   

ol.list-styling li:before {
  content: counter(listStyle, lower-alpha);
  counter-increment: listStyle;
  position: absolute;
  left: -34px;
  top: -1px;
  color: white;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  text-transform: uppercase;
}

ol.list-styling li {
  position: relative;
}

ol.list-styling {
  list-style-type: none;
}

.lists-style {
  border-top: 1px solid #a8a6a6;
}

ol.list-styling li {
  margin: 20px 0px;
}

/* ol.list-styling li:before {
    content: "A";
    margin-right: 14px;
    background-color: skyblue;
    border-radius: 100%;
    padding: 5px;
} */

.print {
  text-align: right;
  margin: 3rem 0px;
  cursor: pointer;
  margin-left: 398px;
  font-size: 16px;
}

.shape-list .col-md-3 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.email-section {
  display: flex;
  gap: 20px;
  padding: 35px;
  justify-content: center;
  background: #f7f2ee;
  max-width: 710px;
  margin: auto;
}

.email-section p {
  max-width: 600px;
  margin-bottom: 0px !important;
}

.message_img {
  width: auto;
  height: 50px;
  margin: auto;
}

.shapebasic_img {
  width: 100%;
}

.shapebasic_row .col-md-3 {
  padding: 0px !important;
}

.lists-style {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

@media screen and (max-width: 767px) {
  .email-section {
    flex-direction: column;
    text-align: center;
  }
  .desktop_view{
    display: none;
  }
  .mobile_view{
    margin-left: 0 !important;
  }
}
@media screen and (min-width: 768px) {
.mobile_view{
  display: none;
}
}