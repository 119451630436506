.tryons-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 97px);
  background-image: url("../../../../public/images/tryons_background.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.tryons-content h1 {
  margin-bottom: 24px;
  font-weight: bold;
  text-align: center;
}

.bolt-text {
  height: 100%;
  font-size: 70px;
  font-weight: 600;
  text-align: right;
  line-height: 1;
}

.bolt-text > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.desc-1 {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.desc-1 .text {
  font-size: 20px;
  width: 300px;
}

@media screen and (max-width: 1060px) {
  .tryons-section {
    background-size: cover;
  }
}

@media screen and (min-width: 1600px) {
  .tryons-content {
    font-size: 1.75rem;
    max-width: 800px;
    max-height: 450px;
  }

  .bolt-text {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) {
  .tryons-section {
    height: 500px;
    background-position: center;
  }

  .tryons-content {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .tryons-content h1 {
    color: #ec7b3d;
    margin-bottom: 16px;
    text-shadow: #fff 1px 1px 1px;
  }

  .tryons-content .desc-1 .text {
    font-size: 20px;
    width: 100%;
  }

  .tryons-content .desc-1 .bolt-text {
    font-size: 2rem;
    display: block;
    text-align: center;
  }
}

@media screen and (min-width: 768px) {
  .bolt-text > div {
    justify-content: flex-end;
  }
}

@media screen and (min-width: 1024px) {
  .tryons-section {
    height: calc(100vh - 113px);
  }
}

@media screen and (min-width: 1200px) {
  .bolt-text {
    font-size: 90px;
  }

  .desc-1 .text {
    font-size: 24px;
  }
}
