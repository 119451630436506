.payment-heading {
  text-align: center;
  margin-bottom: 60px;
}
button.btn.btn-light {
  display: flex;
  margin: auto;
  justify-content: center;
  margin-top: 40px;
  /* border-radius: 35px; */
}
.form-check-label p {
  font-size: 13px;
}
.form-check-input:focus {
  border-color: none !important;
  outline: 0 !important;
  box-shadow: none !important;
}
.form-check-input:checked {
    background-color: #000 !important;
    border-color: #000 !important;
}

