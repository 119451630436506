h1.privacy_head {
     display: flex;
     /* align-items: center; */
     justify-content: center;
     margin-bottom: 60px;
 }
 table.privacy_table {
     border: 1px solid;
 }
 td {
     border: 1px solid;
 }
 .privacy-area {
    padding: 40px 130px 0px 130px;

 }
 .privacy_ul{
     list-style: inherit;
     padding: 0;
 }


 @media screen and (max-width:767px){
    .privacy-area {
        padding:40px 50px 0px 50px;
    }
 }