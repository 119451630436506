.get-started {
    font-size: 2rem;
}
.menu {
    max-width: 190px;
    margin: 0 auto;
}
.menu p:hover{
    cursor: pointer;
    text-decoration: underline;
}
.body-shape {
    font-family: 'Poppins';
    font-size: 24px;
    line-height: 32px;
    color: #040000;
}
.body_shape.already-account {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.body_shape.already-account u{
    cursor: pointer;
}

@media screen and (max-width:767px){
    .body-shape-comp {
        padding-top: 100px !important;
    }
    .row.body_shape {
        padding: 0px 0 !important;
    }
    /* .heading-getstarted {
        padding: 25px 0 !important;
    } */
}