.login-form-reset {
    display: grid;
    justify-content: center;
    margin-top: 100px;
    text-align: center;
}
.reset-msg{
    color:green
}
.wrong-msg{
    color: red;
}
.reset-login {
    margin-top: 20px;
}
.reset-login u{
    cursor: pointer;
}