/* .main-img {
  background: url("../../../../public/images/Blue.png");
} */
.main-img-1 {
  background: url("../../../../public/images/Red.png");
}
.main-img-2 {
  background: url("../../../../public/images/Orange.png");
}
.main-img-3 {
  background: url("../../../../public/images/Blue.png");
}
.main-img-4 {
  background: url("../../../../public/images/Purplenew.png");
}
.main-img-5 {
  background: url("../../../../public/images/Green.png");
}
.main-img- {
  background: url("../../../../public/images/Yellow.png");
}

.confirmation-hero-content {
  position: absolute;
  top: 50%;
  text-align: center;
  width: 100%;
  left: 0;
  right: 0;
}

.confimation-hero-section {
  height: 320px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.confirmation-hero-content.success-hero-content {
  position: relative;
  top: 30px;
  display: grid;
  place-content: center;
  place-items: center;
  height: 320px;
}
.success-hero-content h4{
  color: #fff;
}

.success-hero-content h2 {
  color: #fff;
  font-size: 70px;
  font-weight: 800;
  padding-bottom: 1.5rem;
}
.bodyshape-content {
  padding-top: 50px;
  display: flex;
}
/* li {
  list-style: disc;
} */
/* .list-color ul li::before {
  content: "+";
  margin-right: 10px;
  color: orange;
  height: 100%;
  font-size: 1.4rem;
  position: relative;
  top: 2px;
} */
.list-color li{
  margin-top: 30px;
}
.howitworks-container.streamline-containers {
  background-color: white;
  width: 100%;
  padding: 70px 0 70px;
}
ul.body-shape-list {
  margin-top: 50px;
}
.color-bar.loginbar {
  padding-top: 70px;
}
.section-dash{
  background: antiquewhite;
  display: flex;
  justify-content: center;
}
.step-content.pt-2.dash {
  margin-bottom: 43px;
}
.dash h4{
  color: #ec7b3d;
}
@media screen and (max-width: 767px) {
  .list-color li{
    margin-top: 12px;
  }
  .get-start-img {
    /* margin-bottom: 16px; */
}
.step-content.pt-2.dash {
  margin-bottom: 0px !important;
}
  .color-bar.loginbar {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
  }
  .how-it-works-icon.font-lg-32.sheet {
    margin-bottom: 0;
}
.print {
  margin: 1.2rem 0px;
}
  .dash-vids.how-it-works-icon.font-lg-32 {
    margin-bottom: 0px;
    margin-top: 12px;
}
  .print {
    text-align: left !important;
}
  body ul.list-color {
    font-size: 1.4rem;
    font-weight: 300;
    /* margin-left: 100px; */
}
.list-color li{
  margin-left: -97px !important;
  font-size: 18px;
}
  .section-dash .step-content p {
    color: black;
    font-size: 12px;
    font-weight: 500;
}
  .section-dash .dress-steps .col-6.dash_main {
    padding: 6px;
}
  .section-dash .dress-steps .row {
    margin: 5px !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
}
.dress-steps .dash_main .dress-steps-item{
  padding: 12px !important;
}
  .howitworks-container.streamline-container {
    margin-top: 30px;
  }
  .bodyshape-content {
    padding-top: 22px;
    /* display: table-row; */
    display: flex;
    flex-direction: column;
}
  .confimation-hero-section {
    height: 200px !important;
  }
  .confirmation-hero-content.success-hero-content {
    position: relative;
    top: 60px;
    display: grid;
    place-content: center;
    place-items: center;
    height: 138px;
}
.dashboard.how-it-works-icon.font-lg-32 {
  margin-bottom: 10px;
}
}

.dash_content {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}
.dress-steps .dash_main .dress-steps-item{
  width: 100%;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fff;
  height: unset;
}

.step-label.dash p{
  margin: 0;
}
.section-dash .dress-steps .dress-steps-item img {
  max-width: 170px;
}
.section-dash .dress-steps .row {
  margin: 26px;
  margin-left: 12px;
  margin-right: 12px;
}

