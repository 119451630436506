.step-display-container {
    padding: 74px 0;
}
.step-display-row {
    padding-bottom: 74px;
}
.step-display-item-content {
    max-width: 400px;
}
.step-display-item-content h4 {
    margin-bottom: 17px;
    font-size: 32px;
}
.no-credit-card-need {
    padding: 100px 0 17px;
}

@media screen and (max-width: 767px) {
.no-credit-card-need {
    padding: 0px 0 17px;
}
.step-display-container {
    padding-bottom: 20px;
}
}