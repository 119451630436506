.story {
  margin-top: 97px;
}

.story__hero__content-block {
  margin: 40px auto 32px;
}

.story__hero__heading {
  max-width: 500px;
}

.story__hero__image-block {
  padding: 0;
}

.story__hero__image {
  width: 100%;
  height: 50vh;
  object-fit: cover;
}

@media screen and (min-width: 768px) {
  .story__hero__image {
    height: 70vh;
  }
}

@media screen and (min-width: 1024px) {
  .story {
    margin-top: 113px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .story {
    margin-top: 113px;
  }

  .story__hero__heading {
    max-width: 300px;
  }
}
