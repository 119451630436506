.form-heading {
  padding-bottom: 60px;
}
.body-shape-comp.payment-form {
  color: rgba(96, 96, 96, 1);
}
.image {
  display: flex;
  margin-bottom: 10px;
}
span.step-no {
  border: 1px solid #c4c4c4;
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  padding: 3px;
  font-size: 15px;
  margin-right: 20px;
}
.payment-form .image img {
  background: #c4c4c4;
  padding: 12px;
  border-radius: 18px;
}
.border-right {
  border-right: 3px solid #e5e5e5;
}
.form-layout {
  padding-left: 40px;
}
.list-area {
  display: flex;
  /* margin-top: 100px !important; */
}
.first_last_field {
  display: flex;
  justify-content: space-between;
  align-items: start;
}
.terms.form {
  margin-left: 24px;
  margin-bottom: 40px;
}
.listing ul li {
  display: flex;
  line-height: 1.1;
  font-weight: 500;
  font-size: 18px;
  font-family: "Lato", sans-serif;
}
.list-area li:before {
  content: "+";
  margin-right: 10px;
  color: #ec7b3d;
  height: 100%;
  font-size: 1.5rem;
  position: relative;
  top: -4px;
}
.list-area li.not-avilabel:before {
  content: "✕";
  font-size: 14px;
  top: 2px;
  position: relative;
}
.plans-main ul li.not-avilabel:before {
  font-size: 14px;
  color: black;
  top: 2px;
  font-weight: bold;
}
.list-area h5 {
  font-size: 18px;
}

.form-group {
  margin: 20px 0;
  margin-top: 0px;
}
.first_last_field .form-group {
  max-width: 45%;
  flex: 0 0 45%;
}
.action-btn {
  display: flex;
  justify-content: center;
  padding: 0px 0 30px;
}
.action-btn button {
  border-radius: 40px;
  color: #606060;
}
.img-content {
  text-align: center;
  justify-content: center;
  margin: auto;
}
.font-lg-42 {
  /* margin-top: 50px; */
}
.font-lg-34 {
  margin-top: 34px;
}

.container-fluid.form_bg {
  background: #f3f0f7;
  padding-top: 6rem;
  padding-bottom: 8rem;
}

.action-btn .btn-lg-secondary-outline[disabled] {
  opacity: .4;
}
.action-btn .btn-lg-secondary-outline {
  padding: 12px 0;
  border: 1px solid #000;
  border-radius: 0px;
  width: 100%;
  color: white;
  background: black;
}
.form-group.category {
  padding-bottom: 15px;
}
.form-group.package {
  padding-bottom: 27px;
}
.img-content p {
  margin-top: 20px;
  cursor: pointer;
}
.img-content h5 {
  margin-top: 80px;
}
    
.ReactModal__Content.ReactModal__Content--after-open {
  padding: 82px;
  max-width: 900px;
  margin: auto;
}

.form-group.form_email {
  margin-bottom: 25px;
  font-size: 17px;
}
.form_img {
  background: #f1ccbe;
  padding: 46px;
}
.package-content {
  /* background: #fff; */
  padding: 20px;
}
li.not-avilabel {
  padding: 3px 0px;
}

.radio {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 7px;
}
/* input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: black !important;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
    color: #000 !important;
} */

input[type="radio"]:checked + label:before {
  background-color: #93e026 !important;
}


@media screen and (max-width: 767px) {
 body .promo_label {
    flex: 0 0 70%;
    max-width: 70%;
}
  .form-layout {
    padding-left: 0px;
  }
  .font-lg-42 {
    margin-top: 0;
  }
  .form-heading {
    padding-bottom: 30px;
  }
  .container-fluid.form_bg {
    padding-top: 3rem;
    padding-bottom: 4rem;
  }
  .img-content h5 {
    margin-top: 48px;
  }
}
/* .red-alert {
  border: 4px solid red;
  box-shadow: 0px 0px 5px 1px red;
} */
select#exampleFormControlSelect1 {
  height: 45px;
}

.container.form_inner {
  max-width: 600px !important;
}
.form_inner .form_email input#exampleFormControlInput1 {
  margin-bottom: 2.5rem;
}
.validation-text {
  position: absolute;
  color: #02b25c;
  font-family: "Lato";
}
button.form_apply_btn[disabled] {
  opacity: .4;
}
.container.form_inner .form_apply_btn {
  padding: 7px 0;
  border: 1px solid #000;
  border-radius: 0px;
  margin-top: 26px;
  color: #000;
  font-family: "Lato", sans-serif;
  width: 100%;
  margin-left: 27px;
  max-width: 190px;
  height: 43px;
  background-color: white;
  font-size: 18px;
  text-align: center;
}
.promo-tag input {
  padding-left: 30px;
  padding-right: 70px;
}
.container.form_inner .first_last_field .form-group {
  max-width: 48%;
  flex: 0 0 48%;
}
.container.form_inner .birthday_field {
  max-width: 100% !important;
  flex: 0 0 100% !important;
}
label {
  margin-bottom: 0px !important;
  font-size: 17px;
  color: #606060;
}
.promo_label {
  flex: 0 0 48%;
  max-width: 48%;
}
.form-group-promo {
  display: flex;
  margin-bottom: 30px;
}
.phone-area {
  color: red;
  /* font-style: italic; */
}
.email-validation {
  color: red;
}
.first-validation {
  color: red;
}
.date-format {
  display: flex;
}
/* .react-tel-input{
  display: none;
} */
.special-label {
  display: none;
}
.promo-tag {
  position: relative;
}
.promo-tag i {
  position: absolute;
  top: 12px;
  left: 10px;
  z-index: 999;
}
.fa-tag:before {
  content: "\f02b";
  color: #02b25c;
}
p.remove {
  position: absolute;
  right: 11px;
  color: #d0357e;
  cursor: pointer;
  margin-top: 5px;
  z-index: 999;
  font-size: 14px;
}
p.free {
  color: #a03af2;
  margin-left: 8px;
  font-size: 20px;
}
p.font-lg-24.promo {
  display: flex;
}
select.form-control:not([size]):not([multiple]) {
  height: 0% !important;
}
.react-tel-input.form-control {
  padding: 0;
  border: none;
}
select#Month {
  max-width: 48%;
  flex: 0 0 48%;
}
select#day {
  max-width: 24%;
  flex: 0 0 24%;
  margin-left: 22px;
}
select#year {
  max-width: 20%;
  flex: 0 0 20%;
  margin-left: 20px;
}
.MuiInput-underline:before {
  border-bottom: 0px !important;
}
.MuiInput-underline:after {
  border-bottom: 0px !important;
}
.container.streamline {
  margin-top: 30rem;
}

button.bg-orange.btn-lg-secondary-outline {
  background-color: #ec7b3d;
  border-radius: 0;
  padding: 12px 16px;
  text-transform: lowercase;
  color: #fff !important;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  animation: 0.5s ease-out 0s 1 slideInFromBottom;
}
button.bg-black.btn-lg-secondary-outline {
  background-color: white !important;
  border-radius: 0;
  padding: 0.5rem 50px;
  text-transform: lowercase;
  color: black !important;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  animation: 0.5s ease-out 0s 1 slideInFromBottom;
}
.form-group.height {
  display: flex;
  gap: 1px;
}
.form-group.size {
  display: flex;
  gap: 1px;
}
.form-group.height button {
  /* flex: 0 0 33.33%;
  max-width: 33.33%; */
  flex: 50%;
 
}
.form-group.size button {
  /* flex: 0 0 33.33%;
  max-width: 33.33%; */
  flex: 50%;
}
button.bg-purple.btn-lg-secondary-outline {
  background-color: #ec7b3d;
  border-radius: 0;
  padding: 12px 16px;
  text-transform: lowercase;
  color: #fff !important;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  animation: 0.5s ease-out 0s 1 slideInFromBottom;
}
button.bg-blue.btn-lg-secondary-outline {
  background-color: white;
  border-radius: 0;
  padding: 0.5rem 50px;
  text-transform: lowercase;
  color: black !important;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  animation: 0.5s ease-out 0s 1 slideInFromBottom;
}
.top {
  margin-top: 130px;
}
.form-control.Plan-info {
  height: 90px;
  background-color: #e8e8e8;
}
.Free-plan {
  display: flex;
}
.plan-month{
  font-weight: 500;
  font-size: 18px;
}
.plan-Name{
  font-size: 18px;
}
@media screen and (max-width: 768px) {
  .form-group.height {
    display: grid;
    width: 100% !important;
    gap: 35px;
  }
  button.bg-black.btn-lg-secondary-outline {
    background-color: white !important;
    border-radius: 0;
    padding: 0px !important;
    height: 52px;
    text-transform: lowercase;
    color: black !important;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    font-family: "Lato", sans-serif;
    animation: 0.5s ease-out 0s 1 slideInFromBottom;
  }
}
.navbar-container.nav-default.opacity_module, .navbar-container.nav-white.opacity_module {
  position: relative;
  z-index: 0;
}