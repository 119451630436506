.home__hero__heading {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
}

.home__hero__sub-heading {
  font-family: 'Lato', sans-serif;
  font-size: 28px;
  line-height: 1.3;
}

.home__hero__circles {
  max-width: 382px;
  margin-bottom: 31px;
  margin-left: -20px;
}

.home__hero__circles img {
  width: 100%;
}

.home__hero__hint {
  color: grey;
  font-family: 'Lato';
  font-weight: 300;
}

.home__hero__cta.btn {
  min-height: auto;
  padding: 12px 20px;
  font-size: 16px;
}

@media screen and (min-width: 375px) and (max-width: 767px) {
  .home__hero__heading {
    flex-direction: row;
  }
}

@media screen and (max-width: 767px) {
  .home__hero__heading {
    align-items: center;
    margin: 0 auto 16px;
  }

  .home__hero__heading h1 {
    text-align: center;
  }
}

@media screen and (min-width: 768px) {
  .home__hero__heading {
    margin-bottom: 0;
  }

  .home__hero__heading h1 {
    margin-bottom: 16px;
  }

  .home__hero__circles {
    margin-left: -20px;
  }
}
