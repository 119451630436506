h1.story_head {
  text-align: center;
  margin-bottom: 50px;
}

.howitworks-content .a {
  margin-top: 100px;
}
.story-line {
  margin-top: 100px;
  margin-left: 155px;
}

.heading-story {
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading-getstarted.py-0.story {
    margin-left: 120px;
    /* margin-top: 42px; */
    padding: 60px;
}

.storycard-img {
  
    /* margin-left: 115px; */
}
.storycard-img-1 {
    height: 10px;
    width: 30px;
    margin-bottom: 25px;
}
.story-card .card-body {
    background: #F8F1EC;
}
.story-card{
    margin-top: 90px;
    display: flex;
    justify-content: center;
    margin-bottom: 140px;
}
.story-card p.card-text{
    font-family: sans-serif;
    font-size: 18px;
    margin-bottom: 26px;
    padding-right: 70px;
}
.img-text {
    display: flex;
    justify-content: space-around;
    font-size: 17px;
}
img.card-img-bottom {
    height: 58px;
    width: 63px;
    border-radius: 50%;
}
.storycard-img {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.storycard-img p {
    margin-right: 20px;
    margin-bottom: 0;
    font-size: 16px;
    margin-right: 39px;
}
.text-story {
    padding: 110px;
    background: #F9F7FA;
}
.story-card .card {
    height: auto;
    margin-bottom: 40px;
    border-radius: 0% !important;
    max-width: 24rem;
    margin: 0 25px;
}
@media screen and (max-width:767px){
.heading-getstarted.py-0.story {
    margin-left: 0px;
    padding: 30px 0 !important;
}
.story-our .heading-getstarted .get {
    margin-top: 0px !important;
}
.story-our .text-story {
    padding: 50px 15px;
}
.story-our .story-card {
    margin-top: 40px;
    flex-direction: column;
}
.story-card .card {
    margin: 0 0 25px;
    max-width: 100%;
}
.story-our .howitworks-content {
    padding: 1.5rem 0rem;
}
.story-our .story-line {
    margin-top: 0;
    margin-left: 0;
}
.story-our .multi-color-section {
    width: 100%;
}
.story-our .hero-img {
    max-width: 100%;
    margin-bottom: 15px;
    margin-left: 0;
}
}