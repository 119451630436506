.right-col-hero {
  background-image: url("../../../images/ShapeShopp_Hero_image.jpg");
}
.hero-img {
  max-width: 382px;
  margin-bottom: 31px;
  margin-left: -20px;
}
.hero-img img {
  width: 100%;
}
@media screen and (max-width:691px){
 .right-col-hero {
  order: -1;
} 
}