.streamline-container{
    background: #fff;
    overflow: hidden;
    /* margin-top: -100px; */
}
.dress-steps .price-item-1 {
    background: #f1ecf4;
}
.dress-steps .price-item-2 {
    background: #e3dbe9;
}
.dress-steps .price-item-3 {
    background: #d4c8de;
}
.dress-steps .dress-steps-item:hover {
    background: #fff;
    border-color: #000;
}
.package-details {
    max-width: 520px;
}
.package-details li {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-family: 'Lato', sans-serif;
    font-size: 19px;
    font-weight: 500;
    line-height: 1.5;
}

.baar{
    margin-bottom: 100px;
}
.dress-steps-item span {
    position: absolute;
    top: -1px;
    background: #000;
    color: white;
    padding: 12px 19px;
    left: -1px;
    font-size: 14px;
  }
  .how-it-works-icon.font-lg-32 {
    margin-bottom: 60px;
}
.howitworks-content.landingbar {
    position: relative;
    bottom: 145px;
}

.cardlist li:before {
    content: "\f0da";
    font-family: fontawesome;
    color: black;
    font-weight: 1000;
    font-size: 14px;
    position: relative;
    top: 0;
    left: -4px;
}

ul.package-details-membership {
    font-size: 10px;
}
.love-friend {
    padding: 210px 0 0;
}
.learn {
    border-radius: 0;
    padding: 1rem 3rem;
    text-transform: lowercase;
    color: #fff !important;
    background: #111111 !important;
    box-shadow: 0px 0px 5px rgb(172, 172, 172);
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    font-family: "Lato", sans-serif;
    animation: 0.5s ease-out 0s 1 slideInFromBottom;
    /* margin-left: 80px; */
    color: white;
}
.dresslink_wrapper {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    align-items: flex-end;
    margin-top: 20px;
}
button.btn.learn:focus {
    box-shadow: none;
}


@media screen and (max-width:767px){
    .mobileview-width{
        margin-top: 0rem;
        min-height: auto;
    }
    .step-display-item-content h4 {
        margin-bottom: 17px;
        font-size: 26px;
    }
    .step-display-item-content .font-lg-24 {
        font-size: 20px;
    }


    .row.align-items-center.step-display-row .col-md-6{
        flex-shrink: 0;
        margin: auto;
        width: 100%;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
        margin-top: var(--bs-gutter-y);
    }
    .align-items-center {
        gap: 15px;
    }

    }

    @media (max-width){
        .dresslink_wrapper {
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }
