.days-wrapper {
    padding: 100px 0;
    margin-top: -60px;
}

.days-main {
    flex: 0 0 20%;
    max-width: 20%;
}
.newsletter-inner span{
  padding-right: 20px;
}
.newsletter-inner {
    max-width: 800px;
    margin: 0 auto;
    background: #F9F7FC;
    padding: 40px;
    display: flex;
}
.stay-connected-wrapper {
    padding: 80px 0;
    max-width: 800px;
}
.shoot {
    padding-bottom: 100px;
}
.shoot img {
    width: 100%;
}
.shop_row{
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 5rem;
}
.Style-content{
    padding-left: 30px;
    padding-top: 2rem;
    max-width: 888px;
    padding-bottom: 8rem;
}
.shop_image {
    display: flex;
    justify-content: center;
}
.shopproduct_heading {
    padding-top: 1rem;
}
.shop_img {
    width: auto;
    height: 350px;
}
.newsletter img {
    width: auto;
    height: 33px;
    margin-top: 7px;
}
.newsletter p {
    margin: 0;
    padding: 0;
    margin-top: auto;
}
.newsletter {
    display: flex;
    gap: 20px;
    background: #f8f2ed;
    padding: 55px; 
}
.container.newsletter_section {
    max-width: 900px;
    margin-top: 8rem;
    margin-bottom: 8rem;
}
.catalogs {
    display: flex;
    margin-top: 100px;
    justify-content: center;
}
img.confirmation-ladies {
    height: 500px;
    width: auto;
  
}
.container.sty {
    margin-top: 85px;
}
.wrapper-img {
    display: flex;
    justify-content: center;
}

@media screen and (max-width:767px){
    .newsletter img {
        width: auto;
        height: 33px;
      margin: auto;
    }
    img.confirmation-ladies {
        height: 245px;
        width: auto;
    }
}