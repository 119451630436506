.shape_content{
    margin-top: 2.5rem; 
    padding-left: 30px;  
    margin-bottom: 3rem;
    font-size: 1.4rem;
    font-weight: 300;
}

.days-wrapper {
    padding: 100px 0;
}
.shape_orange_color{
    color: #EC7B3D;
}
.days-main {
    flex: 0 0 20%;
    max-width: 20%;
}
.newsletter-inner span{
  padding-right: 20px;
}
.newsletter-inner {
    max-width: 800px;
    margin: 0 auto;
    background: #F9F7FC;
    padding: 40px;
    display: flex;
}

.days-img {
    text-align: center;
    /* margin-bottom: -70px; */
}
.day_headings {
    max-width: 110px;
    margin: auto;
    padding-left: 13px;
    padding-bottom: 1.5rem;
}
img.img-fluid.shape_days {
    width: 200px;
}
@media screen and (max-width: 767px) {
    .shape_content {
        margin-top: 20px;
        padding-left: 0;
    }
}