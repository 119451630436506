.pc.navbar-submenu a {
  white-space: nowrap;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 500;
}
.navbar-login button {
  background: inherit;
  padding: 0.5rem 2.5rem;
  border: 1px solid #000;
  font-weight: 550;
  cursor: pointer;
  width: 81px;
}
.logindetails{
  background: #fff;
  padding: 12px 18px !important;
  border: 1px solid #000;
  /* font-weight: 600; */
  font-size: 18px;
  font-family: "Lato", sans-serif;
  border-radius: 33px;
  color: #000;
  white-space: nowrap;
}
.logindetails:hover{
  background: #000;
    color: #fff;
}
.navbar-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  z-index: 10000;
  margin: auto;
  position: fixed;
  top: 0;
  padding: 1.5rem 3rem;
  font-size: 20px;
}
.nav-tags {
  padding: 0 20px;
}
.navbar-submenu {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  text-align: center;
  align-items: center;
}
.navbar-login {
  display: grid;
  justify-items: end;
}
.navbar-login {
  display: grid;
  justify-items: end;
}
.mobile {
  display: none;
}
.nav-white {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
  transition: all 1s ease-in-out;
}
.nav-default {
  background-color: none;
  box-shadow: none;
  transition: all 2s ease-in-out;
}
.hamburger img {
  height: 100%;
  margin-top: -0.5rem;
  cursor: pointer;
}
img.sdf {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  border-radius: 100%;
  border-radius: 100%;
}
@media screen and (max-width: 1840px) {
  .navbar-submenu {
    padding-left: 4rem;
  }
  .navbar-container {
    grid-template-columns: 1fr 2fr 1fr;
  }
}
@media screen and (max-width: 1580px) {
  .navbar-container {
    grid-template-columns: 1fr 4fr 1fr;
  }
}
@media screen and (max-width: 980px) {
  .pc {
    display: none;
  }
  .mobile {
      display: flex;
      padding-top: 12px;
  }
  .hamburger {
    text-align: right;
  }
}

.navbar-container-mobile {
  display: block;
  position: fixed;
  overflow: hidden;
  transform: scaleX(0);
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100000;
  width: 100%;
  height: 100%;
  background-color: rgba(145, 145, 145, .4);
}
.navbar-container-mobile-inner {
  width: 70%;
  box-shadow: 0px 0px 10px silver;
  background-color: rgb(252, 252, 252);
  height: 100%;
}
.navbar-container-mobile-shownav{
  transform: scaleX(1);
  transform-origin: left;
  opacity: 1;
  transition: transform .5s ease-in-out,opacity 1s ease-in-out;
}
.navbar-mobile-submenu{
  padding: 1rem;
}
.navbar-mobile-submenu div{
margin-top: 2rem;
}
.navbar-container-mobile .navbar-login {
  justify-items: start;
  padding: 1rem;
}
.mobile-logo {
  padding: 1rem;
}
.btn-style{
  border-color: black !important;
}

.navbar-container {
  min-height: 97px;
  padding: 16px 12px;
}

@media screen and (min-width: 1024px) {
  .navbar-container {
    padding: 24px 48px;
    min-height: 113px;
  }
}
