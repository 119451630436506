/* .body-shape-comp {
  padding-top: 130px;
} */

.row.body_shape {
  padding: 50px 0;
  overflow: hidden;
}

.get-start-img:hover {
  border: 1px solid grey;
  /* border-right: none; */
}

.row.body_shape .col-lg-4:nth-child(3n) .get-start-img:hover{
  border: 1px solid grey;
  /* border-left: none; */
}

.cardlist ul li {
  display: flex;
  padding-bottom: 20px;
  font-weight: 500;
}

.heading-getstarted {
  max-width: 500px;
  padding: 50px 0;
}

.heading-getstarted h2 {
  padding-top: 20px;
}

.get {
  font-size: 14px;
  color: #ec7b3d;
  font-weight: bold;
}

.getstarted-main .get-text {
  font-weight: 500;
  padding: 15px 0;
}

.get-start-img {
  background: #f1ccbe;
  padding: 30px 20px;
  text-align: center;
  cursor: pointer;
  /* border: 2px solid transparent; */
  border: 1px solid #fff;
}
.selected{
  border: 1.5px solid black;
}

.get-start-img.active {
  background: #f1ccbe;
  border: 1px solid #f1ccbe;
  color: #ec7b3d;
}
  .text-continue {
    margin-left: 20px;
  }
.body-shape-comp.get-start {
  margin-top: -50px;
}
@media screen and (max-width: 767px) {
  .continue-btn{
    opacity: 0;
    display: none;
    border: none !important;
    border-left: none;
}
  .col-md-6.col-5.bodyshape-width {
    width: 38%;
    margin-bottom: 1rem;
  }
.get-start0card:hover {
    border: 1px solid grey;
}
  .col-md-6.col-7.bodyshape-width {
    width: 56%;
    margin-bottom: 1rem;
  }

  .love-friend {
    padding: 48px 0 0 !important;
  }

  .cardlist ul li {
    font-size: 16px;
    padding-bottom: 5px;
  }
  .row.body_shape .col-lg-4:nth-child(3n) .get-start0card:hover .continue-btn { 
    left: auto!important;
    right: 0;
  }
  .get-start0card:hover .continue-btn { 
    left: 0% !important;
    right: 0;
    max-width: 100%;
  }
  .get-start0card:hover .continue-btn {
    position: inherit !important;
    bottom: 0;
    right: -96%;
    width: 100%;
    top: 0;
    max-width: unset !important;
    height: calc(99.75% - 15px);
    transition: .5s cubic-bezier(0, 0, 0.22, 0.02);
    background-color: #fff;
    margin: 0;
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 9;
    margin-bottom: 15px;
    /* border: solid 0.5px !important;
    border: 1px solid grey !important;
    border-top: none !important; */
  }
  .get-start-img:hover {
 
    border: none;
}
.row.body_shape .col-lg-4:nth-child(3n) .get-start-img:hover {
  border: none;
}
}
.row.body_shape .col-lg-4:nth-child(3n) .get-start0card:hover .continue-btn { 
  opacity: 1;
  left: -54%;
  border: 1px solid grey;
  border-right: none;
}

.get-start0card:hover .continue-btn { 
  opacity: 1;
  left: 96%;
  
}
.continue-btn{
    opacity: 0;
    display: none;
    border: 1px solid grey;
    border-left: none;
}
  
/* .get-start0card:hover .continue-btn {
  position: absolute;
  bottom: 0;
  right: -96%;
  width: 100%;
  max-width: 250px;
  height: calc(100% - 15px);
  transition: .5s cubic-bezier(0, 0, 0.22, 0.02);
  background-color: #fff;
  margin: 0;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 9;
  margin-bottom: 15px;
} */
img.img-fluid.rounded-start.size {
  max-width: 100%;
  height: auto;
  width: 202.5px;
}

.get-start0card:hover .continue-btn {
  position: absolute;
  bottom: 0;
  right: -96%;
  width: 100%;
  top: 0;
  max-width: 250px;
  height: calc(99.75% - 15px);
  transition: .5s cubic-bezier(0, 0, 0.22, 0.02);
  background-color: #fff;
  margin: 0;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 9;
  margin-bottom: 15px;
}


.text-continue .continue-btn{
  position: absolute;
  bottom: 0;
  left: 0%;
  width: 100%;
  max-width: 250px;
  height: calc(100% - 15px);
  transition: .5s cubic-bezier(0, 0, 0.22, 0.02);
  background-color: #fff;
  margin: 0;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 9;
  margin-bottom: 15px;

}