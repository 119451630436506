.blog-image img {
  width: 672px;
  height: 400px;
  /* object-fit: cover; */
  /* margin-left: 250px; */
  margin: auto;
  display: flex;
  border: 1px solid grey;

}

.blog-title h1 {
  font-size: 40px;
  font-weight: 700;
  font-family: interstate,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  line-height: 1.13;
}
.line{
  height: 1px;
  margin-top: 70px;
}
.avtar {
  display: flex;
  gap: 12px;
  justify-content: start;
  align-items: center;
}
.avtar .author-image img {
  width: 45px;
  height: 45px;
  border-radius: 100%;
}

.avtar .author-name p {
  margin-bottom: -3px !important;
}
.avtar p.avatar_title {
  font-weight: 500;
}
.blog-title {
    text-align: center;
    margin-bottom: 35px;
}
.author-name {
  display: flex;
  justify-content: center;
  font-size: 15px;
  letter-spacing: 3.3px;
  color: #989694;
  margin-bottom: 25px;
}

.blogbody {
  text-align: center;
  padding: 15px;
  text-align: left;
  letter-spacing: 1px;
  margin-top: 40px;
}

.blogbody h1 {
  margin-top: 48px;
  margin-bottom: 24px;
}

.blogbody h2 {
  margin-bottom: 16px;
}

.blogbody table {
  margin: auto;
  text-align: center;
}

.blogbody table tr {
  display: flex;
}

.blogbody table td {
  border: none;
}

.blogbody img {
  max-width: 250px;
  max-height: 250px;
  margin-top: 16px;
}

.blog {
  margin-top: 40px;
}

.blog__hero-post__img {
  height: 100%;
}

.blog__posts {
  padding-inline: 15px;
  margin-top: 50px;
}

.blog__post-title,
.blog__post-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog__post-title {
  -webkit-line-clamp: 3;
  margin-bottom: 24px;
  font-weight: 500;
  line-height: 1.3;
}

.blog__post-description {
  -webkit-line-clamp: 4;
}

@media screen and (max-width: 767px) {
  .blog-image img {
    max-width: 250px;
  }

  .blogbody table tr {
    flex-direction: column;
  }
}

@media screen and (min-width: 768px) {
  .blog__posts {
    padding-inline: 0;
  }

  .blog__post-title {
    min-height: 78px;
  }

  .blog__post-description {
    min-height: 96px;
  }

  .blogbody {
    padding: 30px;
  }

  .blog-data {
    padding: 0 20px;
  }

  .blog-title h1 {
    margin-top: 30px;
  }

  .blogbody table tr {
    gap: 30px;
  }

  .blogbody img {
    max-width: 300px;
    max-height: 300px;
  }
}

@media screen and (min-width: 1024px) {
  .blog {
    max-width: 860px;
  }
}

@media screen and (min-width: 1200px) {
  .blog {
    max-width: 1024px;
  }

  .blogbody img {
    max-width: 450px;
    max-height: 450px;
  }

  .blogbody table tr {
    gap: 100px;
  }
}
