.bottominfo {
  margin-top: 60px;
}
.edit-btn {
  margin-top: 32px;
  margin-bottom: 50px;
}
.readonly {
  margin-top: 70px;
}
.readonly h6 {
  font-size: 20px;
}
.disable {
  border-radius: 0;
  padding: 1rem 3rem;
  text-transform: lowercase;
  color: #fff !important;
  background: #a19a9a !important;
  /* box-shadow: 0px 0px 5px rgb(172 172 172); */
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  animation: 0.5s ease-out 0s 1 slideInFromBottom;
}
i.fa.fa-envelope {
  margin-top: 3px;
}
i.fa.fa-globe {
  margin-top: 3px;
}
i.fa.fa-upload {
  margin-top: 3px;
}
input.change-profile-file {
  margin-top: 6px;
}

input.upload_preview {
  width: 400px;
  border: none;
}
.body-group {
  display: flex;
  flex-direction: column;
}
.body-group {
  display: flex;
  flex-direction: column;
}

select.select-color {
  padding: 10px;
  border-radius: 0;
  background-color: white;
}
.edit-info:hover {
  background: #fff !important;
  color: #111111 !important;
  border: 1px solid;
}
.setting_modal {
  height: 400px;
  width: 400px !important;
  position: absolute;
  inset: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px !important;
}
/* p.btn.update-info {
  margin-left: 60px;
} */
.plans {
  display: grid;
}
p.btn.plan-info {
  border-radius: 0;
  padding: 1rem 3rem;
  text-transform: lowercase;
  color: #110d0d !important;
  background: white !important;
  box-shadow: 0px 0px 5px rgb(172, 172, 172);
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  text-transform: capitalize;
}

p.btn.plannn-info:active {
  background: #000 !important;
  color: #fff !important;
}
.buy-plan {
  border-radius: 0;
  padding: 1rem 3rem;
  /* text-transform: lowercase; */
  color: #110d0d !important;
  background: white !important;
  box-shadow: 0px 0px 5px rgb(172, 172, 172);
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  font-family: "Lato", sans-serif;
}
.buy-area {
  margin-bottom: 70px;
}
.buy-text {
  margin-top: 50px;
}
.red{
  color: red;
}
/* .pic{
  display: flex;
  margin-top: 20px;
} */
.pic img {
  height: 10px;
  margin-top: 6px;
}

.cancel-planss {
  height: 200px;
  width: 516px !important;
  position: absolute;
  inset: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px !important;
  margin: auto;
  text-align: center;
}

.cancel-plansss {
  height: 275px;
  width: 516px !important;
  position: absolute;
  inset: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px !important;
  margin: auto;
  text-align: center;
}
.cancel-plansss p {
  margin-top: 31px;
  font-size: 18px;
}
.cancel-planss p {
  margin-top: 60px;
  font-size: 18px;
}
.cancel-plan {
  height: 200px;
  width: 516px !important;
  position: absolute;
  inset: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px !important;
  margin: auto;
  text-align: center;
}
.body-shapechange {
  height: 600px;
  width: 516px !important;
  position: absolute;
  inset: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px !important;
  margin: auto;
  text-align: center;
}
.get-start-img.bodywidth {
  width: 235px;
  margin: auto;
}
.get-start-img.bodywidth:hover {
  border: none;
  border-right: none;
}
.planarea{
  margin-bottom: 50px;
}
.text-center {
  margin-top: 20px;
}
.cancel-plan_btn {
  display: flex;
}
p.btn.btn.edit-infoo {
  margin-left: -17px;
  margin-top: 10px;
}
.active {
  border-radius: 0;
  padding: 1rem 3rem;
  text-transform: lowercase;
  color: white !important;
  background: #110d0d !important;
  box-shadow: 0px 0px 5px rgb(172, 172, 172);
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  text-align: center;
}
.nhh {
  background-color: black;
  color: white;
}
.row.pp {
  display: flex;
}
.expiry-date {
  margin-top: 12px;
  /* margin-bottom: 30px; */
  font-size: 16px;
}
.payment-area {
  /* border: 0.5px solid #e9e6e6;
  height: 110px;
  border-radius: 5px; */
}
.account-title.payments {
  /* border: 0.5px solid #e9e6e6; */
  /* padding: 20px; */
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}
.change-button {
  display: flex;
  margin-left: 58px;
  font-size: 19px;
  cursor: pointer;
  font-weight: 500;
  margin-top: -20px;
  color: #ec7b3d;
}
/* Media Query */
@media (max-width: 767px) {
  p.btn.update-info {
    margin: 0;
  }
  .change-button {
    display: flex;
    margin-left: 240px;
    font-size: 18px;
    cursor: pointer;
    font-weight: 500;
    margin-top: -80px;
}
  .cancel-plansss p {
    font-size: 15px;
}
  .readonly {
    margin-top: 30px;
  }
  .readonly .col-md-4 {
    margin-bottom: 20px;
  }
  .bottominfo {
    margin-top: 0px;
  }
  .edit-btn {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
.readonly .fafa-icon-users {
  margin-bottom: 55px;
}
.bodyshape_change {
  cursor: pointer;
  margin-top: 10px;
  /* display: flex; */
}
.body_shape h5{
  text-align: center;
  margin-bottom: 29px;
}
 .body_shape_note {
  font-size: 15px;
  color: green;
  margin-top: 14px;
}

@media (max-width: 575px) {
  .setting_modal {
    height: 410px;
    width: 330px !important;
  }
  .cancel-plan {
    height: 220px;
    width: 300px !important;
  }
  .cancel-plansss {
    height: 295px;
    width: auto !important;
    position: absolute;
    inset: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px !important;
    margin: auto;
    text-align: center;
}
.cancel-planss, .cancel-plansss {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  inset: 40px;
  width: 285px !important;
  margin: auto;
  outline: none;
  overflow: auto;
  padding: 20px!important;
  position: absolute;
  text-align: center;}
  .readonly h6 {
    font-size: 18px;
}
.fafa-icon-myaccount {
  margin-bottom: 17px;
}
.readonly .fafa-icon-users {
  margin-bottom: 0px;
}
}
