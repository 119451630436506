.container-fluid.pricing_width {
  max-width: 1344px;
  margin-top: 40px;
}

.hero-section.pricing-hero-section {
  min-height: 75vh;
}

.plans-main .left_pricing_tag {
  position: absolute;
  top: -1px;
  background-color: #000;
  color: white;
  padding: 12px 19px;
  left: -1px;
  font-size: 14px;
}

.plans-main .left_pricing_tag:hover {
  background-color: #000;
}

.plans-main {
  border: 1px solid #0000;
  width: 100%;
  height: 100%;
  padding: 60px 30px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.plans-main.random-color-0 {
  background: #f1ecf4 ;
}

.plans-main.random-color-1 {
  background: #e3dbe9 ;
}
.plans-main.random-color-2 {
  background: #d4c8de ;
}
.hover-prop :hover{
  background: #fff;
  outline: 2px solid #0000;
}
.btn-select :hover{
  background-color: transparent !important;
  border: 1px solid #000;
  color: #000 !important;
}

li.not-avilabel {
  opacity: 0.2;
}

.package-details li:before {
  content: "+";
  position: relative;
  top: 0;
  align-self: flex-start;
  height: 100%;
  margin-right: 10px;
  color: #ec7b3d;
  font-size: 1.5rem;
  line-height: 26px;
}
.plans-main ul li.not-avilabel:before {
  content: "✕";
  font-size: 14px;
  color: black;
  top: 2px;
  font-weight: bold;
}
.heading-getstarted .get {
  margin-top: 160px !important;
}
.package-details-membership ul li {
  display: flex;
  font-weight: 500;
  font-size: 18px;
  font-family: "Lato", sans-serif;
}
.price-streaming {
  background-color: transparent !important;
}
  .plans-testing {
    height: 200px;
    width: 700px !important;
    position: absolute;
    inset: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px !important;
    margin: auto;
    text-align: center;
  }

@media (max-width: 1199px) {
  .pricing-plan {
    padding: 10px 10px 0;
  }
}

@media screen and (max-width: 767px) {
  .pricing_width .row div:first-child .plans-main {
    padding-top: 60px;
  }

  .pricing_width {
    gap: 16px;
  }

  .hero-section.pricing-hero-section .left-col-hero {
    padding: 40px 0 16px;
  }

  .right-col-hero.pricing-banner-img {
    height: 70vh;
    background-position: center;
    background-size: cover;
  }
  .heading-getstarted .get {
    margin-top: 40px !important;
}
  .hero-section {
    padding-top: 0px !important;
    min-height: 726px;
  }

  .plans-main {
    padding: 30px 15px 0;
  }
  .pricing-plan .col-md-4 {
    margin-bottom: 2rem;
  }
  .howitworks-container.price-streaming.pricingstreamline {
    padding-top: 0px;
    margin-top: 0px;
    min-height: unset !important;
    padding-bottom: 0px;
  }
  .howitworks-container.price-streaming.pricingstreamline .howitworks-content {
    padding: 0rem 1rem;
  }
  .getstarted-action-btn .col-md-6 {
    margin-bottom: 4rem;
  }
  .pricing-plan {
    padding: 0px 0 10px;
  }
}

.plans-main:hover {
  border-color: #000;
}

.select-btn-wrapper {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.plan-btn:hover {
  background: #fff !important;
  color: #111111 !important;
  border: 1px solid;
}

@media (max-width: 575px){
  .package-details li {
      font-size: 17px;
  }
  .select-btn-wrapper {
      margin-bottom: 30px;
  }
  .select-btn-wrapper button {
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      text-transform: capitalize !important;
  }
  .plans-testing {
      max-width: 320px !important;
      height: 300px;
  }
.needsclick.kl-private-reset-css-Xuajs1[component="[object Object]"] {
    flex: 0 0 auto !important;
    display: flex;
    align-items: flex-start;
    padding: 0 0 10px !important;
  }
  .needsclick.kl-private-reset-css-Xuajs1[component="[object Object]"] input {
    margin-top: 20px;
    border-radius: 0 !important;
}
.needsclick.kl-private-reset-css-Xuajs1[component="[object Object]"] button{
   width: 100%;
}
.needsclick.kl-private-reset-css-Xuajs1[data-testid="form-row"] {
  flex-direction: column !important;
}
.pricing-plan {
  padding: 120px 0 0;
}
.pricing-plan .pricing-plan {
  padding: 0;
}
}

@media screen and (min-width: 1024px) {
  .hero-section.pricing-hero-section {
    min-height: 80vh;
  }
}
