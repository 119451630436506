/* .modal1 {
    position: absolute;
    width: 28%;
    position: absolute;
    display: flex;
    flex-shrink: 62px;
    margin-top: 51%;
     } */

.modal1 {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 999;
  height: 136px;
}
.modal-dialog {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.modal-content {
  border: 0 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0px !important;
  outline: 0;
  padding: 10px;
  align-items: center;
  flex-direction: row !important;
  justify-content: center;
}

.modal-content button.btn-close {
  position: absolute;
  right: 4px;
  top: 10px;
}
.modal-body {
  padding: 23px 0px !important;
  max-width: 600px;
}
.modal-body p a {
  text-decoration: underline !important;
  color: red !important;
}
.modal-body p {
  margin-bottom: 0px !important;
  font-size: 14px;
}

.cookies_btn {
  padding: 7px 32px !important;
  font-size: 18px !important;
  font-weight: 700 !important;
}

/* Media Query */
@media (max-width: 767px) {
  .modal-content {
    flex-direction: column !important;
    padding: 20px 15px 20px !important;
  }
  .cookies_btn.btn {
    min-height: 56px !important;
  }
  .modal-body h5 {
    font-size: 1.15rem !important;
  }
  .modal-body {
    padding: 0px 0px 5px 0px !important;
  }
  .modal1 {
    bottom: -8px;
    height: 200px;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 100% !important;
    margin: 0 !important;
  }
}
