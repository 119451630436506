body,
html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

::-webkit-scrollbar {
  width: 6px;
  border-left: 1px solid #e6ecf8;
}

::-webkit-scrollbar-thumb {
  background-color: #d6872c;
  border-radius: 10px;
}

body ul {
  list-style: none;
  padding: 0;
}
body ul.list-color {
  font-size: 1.4rem;
  font-weight: 300;
  margin-left: 100px;
}
body {
  font-family: "Poppins", sans-serif !important;
  color: #000 !important;
}

.lato-font {
  font-family: "Lato", sans-serif;
}

.fw-500 {
  font-weight: 500;
}

html body .font-lg-32 {
  font-size: 32px;
}
.left-col-title {
  max-width: 232px;
}

.left-col-title.title-heading {
  max-width: 300px;
}

.font-lg-42 {
  font-size: 42px;
}

.font-lg-30 {
  font-size: 30px;
}

.font-lg-24 {
  font-size: 24px;
}

.font-lg-20 {
  font-size: 20px;
}

.font-lg-18 {
  font-size: 18px;
}
p.pt-3.font-lg-18.confirm {
  font-size: 17px;
}
.font-lg-14 {
  font-size: 14px;
}

body .form-control {
  padding: 10px;
  border-radius: 0;
}

body .form-control:focus {
  box-shadow: none;
}

.form-layout h6 {
  font-size: 16px;
  font-weight: 400;
}

.form-layout input {
  border-radius: 0;
}

.form-layout .form-check-label {
  padding-left: 20px;
}

.text-yellow {
  color: #ec7b3d;
}

span {
  padding: 0;
  margin: 0;
}
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}
.btn-check:focus + .btn .btn:focus {
  outline: 0;
  box-shadow: none !important;
}
html body .btn-lg-secondary-outline {
  background: #000;
  padding: 12px 18px;
  border: 1px solid #000;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  border-radius: 33px;
  color: #fff;
}
html body .navbar-login .btn-lg-secondary-outline:hover {
  background: #000;
  border: 1px solid #000;
  color: #fff;
}
html body sup {
  position: relative;
  top: -12px;
  font-size: 8px;
}
html body .btn {
  border-radius: 0;
  padding: 1rem 3rem;
  text-transform: lowercase;
  color: #fff !important;
  background: #111111 !important;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  animation: 0.5s ease-out 0s 1 slideInFromBottom;
}

u {
  cursor: pointer;
}
.btn:hover {
  background: #fff;
  color: #111111;
}
.btn.btn-lg-primary:hover {
  background-color: transparent !important;
  border: 1px solid #000;
  color: #000 !important;
}
.bg-transparent {
  background-color: transparent !important;
}

.plans-main.active_price {
  background: #f9f7fa;
}

.container-checkbox {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  bottom: 0;
  top: 0;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  top: 9px !important;
  background: transparent !important;
  right: 10px !important;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: #ec7b3d !important;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: block;
  padding: 0;
}

.edit-pic {
  position: relative;
  display: flex;
  justify-content: end;
  top: -10px;
  left: -35px;
  cursor: pointer;
  color: black;
}
.up-nav {
  margin-top: 6px;
  font-size: 2px;
  opacity: 0;
  position: absolute;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 14px;
  top: 7px;
  width: 9px;
  height: 18px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.parent-inner-container {
  max-width: 1750px;
  width: 100%;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  /* background-color: rgb(131, 131, 131); */
}

.parent-container {
  width: 100%;
  background-color: #fff;
}
.carousel-wrapper .carousel .slide .image-container img {
  display: block;
  height: 461px !important;
}

@media screen and (max-width: 768px) {
  .carousel-wrapper .carousel .slide .image-container img {
    height: 198px !important;
    position: absolute;
    top: 21px;
    right: unset !important;
  }
  .left-col-hero.mainPage{
    top: -0px !important;
  }
  .carousel-wrapper {
    width: 100% !important;
    height: auto !important;
  }
  .carousel-wrapper .carousel .carousel-button.prev,
  .carousel-wrapper .carousel .carousel-button.next {
    top: 107px !important;
  }
  .dots .items .item .c-button {
    width: 38px !important;
  }
  .dots,
  .dots .items,
  .text-container {
    padding: 0px !important;
  }
  .shoot.text-centerr {
    margin-top: 0px;
  }
  .btn {
    width: 100%;
    max-width: 100%;
    min-height: 70px;
  }
  .blog-image img {
    width: auto !important;
    height: 190px !important;
    /* object-fit: cover; */
    /* margin-left: 250px; */
    margin: auto;
    display: flex;
    border: 1px solid grey;
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(20%);
  }

  100% {
    transform: translateY(0);
  }
}

a {
  text-decoration: none !important;
  color: #000 !important;
}

/* li {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
} */
.fade-in-section {
  opacity: 0;
  transform: translateY(5vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-in-section.is-visible .btn {
  background: #000;
  color: #fff;
}

.button {
  border-radius: 0;
  /* padding: 0.75rem 2.5rem; */
  text-transform: lowercase;
  /* max-width: 290px;
  min-height: 73px; */
  width: 100%;
  color: #fff !important;
  background: #111111 !important;
  box-shadow: 0px 0px 5px rgb(172, 172, 172);
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  height: 58px;
  width: 165px;
  font-family: "Lato", sans-serif;
  animation: 0.5s ease-out 0s 1 slideInFromBottom;
  align-items: center;
}

body .navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hero-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  position: relative;
  min-height: 100vh;
  height: 100%;
  align-items: center;
}

.right-col-hero.pricing-banner-img {
  background-image: url("/public/images/single-start-banner.jpeg");
}
#dropdown-basic .fa-user:before {
  content: "\f007";
  font-size: 30px;
}
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none !important;
}
.btn-check:focus + .btn-success,
.btn-success:focus {
  box-shadow: none !important;
}
.dropdown-menu.show {
  position: absolute;
  inset: 0px 0px auto auto;
  transform: translate(45px, 61px) !important;
}
.logindetails {
  border-radius: 0px !important;
}
#dropdown-basic {
  padding: 8px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  min-height: 40px;
}
.dropdown-toggle::after {
  display: none !important;
}
.right-col-hero {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: fadein 2s;
}

.hero-section .left-col-hero {
  max-width: 310px;
  margin: auto;
}

.hero-section .left-col-hero.mainPage {
  max-width: 400px;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #ffffff !important;
}

.pricing-hero-section .left-col-hero p {
  padding-top: 8px;
  margin-bottom: 0;
  line-height: 1.3;
  font-size: 32px;
}

a.back-btn {
  margin-bottom: 16px;
  display: block;
}

a.back-btn img {
  padding-right: 7px;
  width: 28px;
}

/* Pricing Plan Main Page */
.getstarted-content .container {
  display: none;
}

.getstarted-content .pricing-plan {
  padding: 60px 10px 0;
}

.get-started h2 {
  padding-bottom: 65px;
  font-weight: 400;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.alert-green {
  color: green;
}

.alert-red {
  color: red;
}

.password-hint-sec {
  background: white;
  /* margin-top: -20px; */
  display: inline-block;
}

.password-hint-wrapper {
  display: flex;
}

.body_shape.already-account h6 {
  cursor: pointer;
}

/* Login sucess */
.nav-gap {
  margin-top: 101px;
}

.style-videos,
.catalog-images {
  padding: 150px 0 0;
}

.video-wrapper video {
  width: 100%;
}

.video-wrapper h4 {
  font-size: 18px;
  margin: 10px 0 70px;
  text-align: center;
}

.catelog-wrapper h4 {
  font-size: 17px;
  margin-top: 15px;
  margin-bottom: 0;
  color: black;
  font-weight: 400;
}

.catelog-wrapper p {
  color: #8d8686;
  font-weight: 400;
}

.catelog-wrapper img {
  border: 1px solid #ec7b3d;
}

.react-player {
  height: 458px !important;
  margin: auto;
  max-width: 816px !important;
  width: 100% !important;
}

.account-fields {
  margin-top: 35px;
}
.account-fields .action-btn {
  display: block;
  margin: 20px 0;
}
.account-fields .action-btn .btn {
  padding: 13px 21px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}
body .form-group .dropdown-container {
  padding: 3px;
  border-radius: 0;
  border: 1px solid #ced4da;
}
.legend {
  color: #000;
}
.video-wrapper {
  margin-top: 155px;
  padding-top: 9.5rem;
}
.react-player__preview {
  height: 450px !important;
}
.style-videos {
  position: relative;
}
.style-videos:before {
  content: "";
  background: url("../public/images/clothes.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;
  height: 700px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /* margin-top: 100px; */
}
li.custom-dots-value-active button {
  padding: 0.5px;
  /* border-radius: 29%; */
  width: 103px;
  height: 8px;
  background: black;
  border: none;
  margin: 5px;
}
li.custom-dots-value button {
  padding: 0.5px;
  /* border-radius: 29%; */
  width: 103px;
  height: 8px;
  background: #80808052;
  border: none;
  margin: 5px;
}
span.play-btn svg {
  width: 20em;
  height: 5em;
  color: white;
}
p.video-line {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  font-size: 17px;
}

.form-group-pass .MuiInput-underline {
  padding: 10px;
  border-radius: 0;
  border: 1px solid #ced4da;
  width: 100%;
  display: block;
}

.mobile-view {
  display: none;
}
/*  media Query  */

.pc.navbar-submenu a:hover {
  border-bottom: 1px solid;
}
.join {
  cursor: pointer;
}

.video-background {
  top: 155px;
  position: absolutex;
  width: 81.6%;
  left: 92px;
  height: 63%;
  background: #fff;
  z-index: -1;
}
.catelog-wrapper img:hover {
  border: 1px solid black;
}
.catelog-wrapper img {
  height: 470px;
  max-height: 470px;
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  html body .font-lg-32 {
    font-size: 26px;
  }

  .font-lg-42 {
    font-size: 28px;
  }

  .font-lg-24 {
    font-size: 18px;
  }

  .body_shape.already-account h5 {
    margin: auto auto 40px;
    font-size: 17px;
  }

  body .menu {
    max-width: 100%;
  }

  li.list-color::before {
    top: 0px;
  }

  .multi-color-section {
    width: 100%;
  }

  .style-videos,
  .catalog-images {
    padding: 50px 0 0;
  }

  .catelog-wrapper {
    margin-bottom: 40px;
  }

  .wrapper .blog_section_bg {
    padding: 40px 15px;
  }

  .blog-container .row {
    padding-right: 0;
  }

  .hero-section .left-col-hero.mainPage {
    margin: 24px auto;
  }

  .hero-section {
    height: 100%;
    padding-top: 73px;
  }

  .mob-vid {
    position: relative;
    height: 227px !important;
  }
  .hero-section .left-col-hero {
    margin-left: 0;
    top: 0;
    position: relative;
  }
  span.play-btn svg {
    width: 120px;
    height: 120px;
  }
  .get-started h2 {
    padding-bottom: 16px;
  }
  .mobile-view {
    display: block;
  }
  .desktop-view {
    display: none;
  }

  .wrapper .streamline-container {
    margin-top: 30px;
  }
  .days-wrapper .days-main {
    flex: 0 0 50%;
    max-width: 50%;
    padding-bottom: 30px;
  }
  .days-main .day_headings {
    max-width: 100%;
    margin: auto;
    padding-left: 0;
    padding-bottom: 0;
    text-align: center;
  }
  .streamline-container .Style-content {
    padding: 20px 0;
    max-width: 888px;
  }
  .howitworks-container .days-wrapper {
    padding: 50px 0 20px;
  }
  .streamline-container .shoot {
    padding-bottom: 40px;
  }
  .catalogs {
    display: flex;
    flex-direction: column;
  }
  .catelog-wrapper img {
    height: 240px;
    max-height: 517px;
    width: 100%;
    object-fit: cover;
  }
  .wrapper .container.newsletter_section {
    max-width: 900px;
    margin-top: 0;
    margin-bottom: 40px;
  }

  .blog_section_bg .blog-container .row {
    margin-bottom: 30px;
    padding-right: 0;
  }
  li.custom-dots-value button,
  li.custom-dots-value-active button {
    border-radius: 0;
    width: 50px;
  }
  .style-videos:before {
    height: 290px;
    margin-top: 40px;
  }
  .video-wrapper {
    margin-top: 60px;
    /* padding: 85px; */
  }
  body .react-player {
    height: 238px !important;
  }
  .video-wrapper h4 {
    font-size: 18px;
    margin: 20px 0 28px;
    text-align: center;
  }
  body .react-player__preview {
    height: 238px !important;
  }
  span.play-btn svg {
    width: 90px;
    height: 90px;
  }
}

/* code at 17-oct-2022 */
.carousel-wrapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  /* width: 1000px; */
  height: 620px;
  background: white;
  background-color: transparent;
}
.carousel-wrapper .carousel {
  position: relative;
}
.carousel-wrapper .carousel .slide {
  position: relative;
}
.dress-steps .how {
  margin: 0px !important;
}
.carousel-wrapper .carousel .slide .image-container {
  /* display: flex; */
  position: relative;
  /* justify-content: center; */
}
.carousel-wrapper .carousel .slide .image-container img {
  height: 450px;
  margin-left: auto;
  margin-right: auto;
}

.carousel-wrapper .carousel .carousel-button {
  position: absolute;
  top: 50%;
  transform: translate(50%, 0);
}
.carousel-wrapper .carousel .carousel-button.prev {
  left: -17px;
  top: 188px;
}
.carousel-wrapper .carousel .carousel-button.next {
  right: 0px;
  top: 188px;
}
.text-container {
  padding-top: 2rem;
  text-align: center;
  width: 100%;
  color: #ffffff;
}
.dots {
  display: flex;
  justify-content: center;
  padding: 10px;
  align-items: center;
}
.dots .items {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 1rem 0;
}
.dots .items .item {
  margin: 5px;
  height: 16px;
}
.dots .items .item .c-button {
  background: #80808052;
  border: none;
  height: 5px;
  width: 103px;
  display: flex;
  cursor: pointer;
}
.dots .item.current .c-button {
  background-color: #110d0d !important;
}
img.carousel-button.prev {
  height: 35px;
  background: aliceblue;
  border-radius: 18px;
  border: 1px solid;
}
img.carousel-button.next {
  height: 35px;
  background: aliceblue;
  border-radius: 18px;
  border: 1px solid;
  transform: rotate(180deg) !important;
}
.react-player iframe {
  width: 100%;
  height: 100%;
}
.vp-video-wrapper.transparent .vp-preview {
  background-size: 100% !important;
}
.player .vp-video-wrapper .vp-preview-cover {
  background-size: cover;
}

.vp-preview {
  background-size: cover !important;
}
.sweet-loading .css-1pzdojn {
  margin: 0 auto;
  color: "#000000";
  border-color: black;
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  top: 0;
  align-items: center;
  bottom: 0;
  justify-content: center !important;
  left: 0px;
}
.left-col-hero.mainPage{
  top: -208px;
}

.home.hero-section .right-col-hero {
  min-height: 70vh;
}
