img.cover {
  height: 364px;
  margin-top: 50px;
  width: 100%;
  margin-bottom: 80px;
}
img.cover {
  height: 364px;
  margin-top: 30px;
  width: 100%;
  object-fit: cover;
}

.blogg-data {
  padding: 0 20px;
}
.blogg-title h1 {
  padding-top: 30px;
  font-size: 30px;
}

.avtar-blogg {
  display: flex;
  gap: 12px;
  justify-content: start;
  align-items: center;
  margin-top: 2rem;
}
.avtar-blogg .blogg-author-image img {
  width: 45px;
  height: 45px;
  border-radius: 100%;
}

.avtar-blogg .blogg.author-name p {
  margin-bottom: -3px !important;
}
.avtar-blogg p.blogg_avatar_title {
  font-weight: 500;
}

.main_blog_container {
  max-width: 1500px;
  width: 100%;
  margin: auto;
  padding-right: 150px;
  padding-left: 115px;
  padding-top: 20px;
}

.blogg-author-image {
  margin-top: -16px;
}
p.blogg_avatar_title {
  margin-bottom: 13px;
}

p.card-text {

  max-width: 100%;
  margin: 0 auto;
  font-size: 15px;
  line-height: 1;
  letter-spacing: 1.10px;
  line-height: 1.6;
  font-family: lato, sans-serif;
}
.card {
  margin-bottom: 40px;
  border-radius: 0% !important;
}
.card-img-top {
  border-radius: 0% !important;
}
.single-text {
  max-width: 100%;
  height: 111px;
  margin: 0 auto;
  font-size: 17px;
  line-height: 1.6;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  letter-spacing: 1.10px;
  line-height: 1.6;
  font-family: lato, sans-serif;
}
.user-time {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

}
/* .container.blogdata {
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
} */
.user-stories {
  color: #989694;
  font-size: 15px;
  letter-spacing: 1.73px;
}
.blogg-date-time {
  color: #27272b;
  font-size: 13px;
  margin-left: 100px;
}
h5.card-title {
  line-height: 1.2;
  margin-bottom: 20px;
}
.blog-time-user {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
}
.single-blogtitle {
  font-size: 25px;
  font-weight: 500;
  line-height: 1.6;
}
.card-body {
  text-align: left;
}

/* Media Query */
@media (max-width:991px){
  .main_blog_container{
    padding: 20px 15px;
  }
  .container.blogdata .col-8 {
      padding-left: 0;
  }
}
@media (max-width: 575px){
 .main_blog_container .card {
      height: auto;
      margin-bottom: 30px;
  }
  .main_blog_container .card img {
      object-fit: cover;
      height: 180px;
      object-position: center;
  }
  .main_blog_container .card p.card-text{
      height: auto;
  }
  .main_blog_container .card .blog-time-user {
      position: static;
      margin-top: 20px;
      align-items: flex-end;
  }
  .blogdata .col-lg-8{
    padding: 0;
  }
  .blogdata img.card-img-top.single {
      height: 220px;
      object-fit: cover;
  }
  .blogdata .single-text{
    height: auto;
    padding-bottom: 20px;
  }
}
