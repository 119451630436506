.password-form {
    display: grid;
    justify-content: center;
    margin-top: 100px;
}
.form-group-pass {
    margin-bottom: 30px;
}

.form-group-pass button.MuiButtonBase-root.MuiIconButton-root {
    position: absolute;
    right: 0px;
    top: 4px;
}
body .form-control {
     position: relative;
}
.error-message{
    color: red;
}