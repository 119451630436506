.login-form {
  display: block;
  justify-content: center;
  max-width: 400px;
  margin: 100px auto 0;
}
 .login-link{
     cursor: pointer;
 }
 span.login-error {
    color: red;
    /* border: 1px solid black; */
    text-align: center;
    font-size: 16px;
    /* margin-left: 45px; */
}
.catalog-images .cat{
    display: flex;
    justify-content: space-evenly;
  }
.log{
  width: 100%;
  max-width: 400px; 
}
.fb-cover-img{
  height: 229px;
  width: 100%;
}
h4.shop-login {
  font-size: 1.4rem;
  font-weight: 300;
}
p.blog-heading {
  font-size: 1.4rem;
  font-weight: 300;
}

p.congratulations {
  font-size: 25px;
  color: white;
  margin-bottom: 45px;
}

  @media screen and (max-width: 767px) {
    p.congratulations {
      font-size: 14px !important;
      color: white;
      margin-bottom: 31px !important;
  }
    .how-it-works-icon.font-lg-32 {
      margin-bottom: 26px;
  }
  h4.shop-login {
    font-size: 1.2rem;
}
.style-videos, .catalog-images {
  padding: 30px 0 0 !important;
}
    p.video-line {
        display: grid;
        justify-content: center;
        margin-top: 6px;
        font-size: 16px;
    }
    .login-form {
      display: block;
      margin-top: 50px;
      padding: 0 15px;
  }    
  }