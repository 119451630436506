.checkout-wrapper {
  padding: 70px 0;
}

.blog-container .row {
  margin-bottom: 30px;
  padding-right: 5rem;

}
.blog-subtitle {
  padding: 15px 0 40px;
}
.blog_section_bg{
  background-color: #f9f7fb;
  padding: 80px 15px;
}
.getstarted_blog{
  padding-bottom: 30px !important;
}
.streamline_width{
  max-width: 612px !important;
}
.bar_color{
  padding-top: 40px !important;
}

@media screen and (max-width: 767px){
.how-it-works-icon.font-lg-32.blogtext {
    font-size: 25px;
}
};