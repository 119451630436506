.getstarted-action-btn {
  height: 630px;
  padding: 40px 0;
  display: grid;
  place-items: center;
}
.footer-content {
  width: 100%;
}
.footer-content p {
  font-family: 'Lato', sans-serif;
  padding-top: 8px;
  font-size: 10px;
}
.social-icons {
  display: flex;
  justify-content: flex-end;
}
.social-icons img {
  margin: 10px;
  height: 40px;
}
img.foot-img {
  width: 225px;
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .getstarted-action-btn {
    /* height: 400px; */
    place-items: flex-start;
  }
}

@media screen and (max-width: 575px) {
  .getstarted-action-btn {
    height: 100%;
  }
  .social-icons {
      justify-content: flex-start;
      padding-bottom: 60px !important;
  }
}
